.legend {
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    padding: 10px;
    font-size: 12px;
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    z-index: 1;
    display: flex;
    flex-direction: column;
}
.legend h4 {
    margin: 0 0 10px;
}
.legend div span {
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    margin-right: 5px;
    margin-left: 5px;
    width: 10px;
}
.legend div {
    display: flex;
    align-items: center;
    margin-right: 10px;
}